<!-- 发布职位显示的数据样式 -->
<template>
  <div class="">
    <transition-group name="linear">
      <template v-for="(item, index) in jobList">
        <div :class="{ main_content: true }" :key="index">
          <div class="show">
            <p>
              {{ item.jd_title
              }}<el-tag
                style="margin-left: 10px"
                v-if="item.jd_hide == 0 && item.jd_status == 1 && isOddjob == 0"
                size="mini"
                type="warning"
                >审核中</el-tag
              >
              <span
                class="view_details"
                v-if="isOddjob == 1"
                @click="publishForm(item, 1)"
                >查看详情</span
              >
            </p>

            <p>
              <span>
                {{ item.place_name }}
                <i></i>
              </span>
              <span v-if="isOddjob == 0">
                {{ item.jd_exp_name }}
                <i></i>
              </span>
              <span v-if="isOddjob == 0">
                {{ item.jd_edu_name }}
                <i></i>
              </span>
              <span v-if="isOddjob == 1">
                {{ item.work_hour }}
                <i></i>
              </span>
              <span v-if="isOddjob == 0"
                >{{ item.jd_salary_min }}-{{ item.jd_salary_max }}元</span
              >
              <span v-else
                >{{ item.jd_salary_max }}元/{{ item.jd_salary_type }}</span
              >
            </p>
          </div>

          <div class="operation" v-if="isOddjob == 0">
            <div>
              <span @click="publishForm(item)">编辑</span>
              <span @click="closeHandle(item)">{{
                item.jd_hide === 1 ? "上架" : "下架"
              }}</span>
              <span @click="detele(item)">删除</span>
            </div>
            <div>
              <p title="投递">
                <span class="iconfont icontoudi"></span>
                {{ item.jd_send_count }}
              </p>
              <p title="浏览">
                <span class="iconfont iconhuabanfuben"></span>
                {{ item.jd_look_count }}
              </p>
            </div>
          </div>

          <div class="operation_odd" v-else>
            <div class="continue">
              <el-button
                type="warning"
                plain
                style="width: 70px; margin-right: 20px"
                v-if="item.odd_status == 1"
                @click="payment(item)"
                >支付</el-button
              >
            </div>
            <div class="continue" v-if="item.odd_status != 1">
              <span @click="again(item)">再次下单</span>
            </div>

            <div
              class="continue"
              v-if="item.odd_status == 1 || item.odd_status == 4"
            >
              <span
                style="border: 1px solid #000; color: #000"
                @click="is_delete(item)"
                >删除</span
              >
            </div>
            <div class="odd_status" v-if="item.odd_status == 1">
              <img src="~@/assets/images/tool/odd_status1.png" alt="" />
            </div>
            <div class="odd_status" v-if="item.odd_status == 2">
              <img src="~@/assets/images/tool/odd_status2.png" alt="" />
            </div>
            <div class="odd_status" v-if="item.odd_status == 3">
              <img src="~@/assets/images/tool/odd_status3.png" alt="" />
            </div>
            <div class="odd_status" v-if="item.odd_status == 4">
              <img src="~@/assets/images/tool/odd_status4.png" alt="" />
            </div>
          </div>
        </div>
      </template>
    </transition-group>
    <!-- 可以写成一个，用三元表达式去写，但是累自己，能简单就简单。。。。。。 -->
    <div
      class="shangxiajia"
      v-if="activeName == 'first' && jobList.length == 0"
    >
      <span v-if="isOddjob == 1">暂无零工需求</span>
      <span v-else>暂无上架职位</span>
    </div>
    <div
      class="shangxiajia"
      v-if="activeName == 'second' && jobList.length == 0"
    >
      暂无下架职位
    </div>

    <div class="payment_wrap" v-if="is_payment" v-loading="loading">
      <div class="payment_content">
        <div class="payment_title">
          <div>请支付</div>
          <div @click="close_order">
            <svg-icon iconClass="close"></svg-icon>
          </div>
        </div>
        <div class="payment_Price">
          <span>岗位薪资</span>
          <span class="Price">{{ jd_salary_min }}元/小时</span>
        </div>
        <div class="payment_Price">
          <span>总时长</span>
          <span class="Price">{{ all_work_hours }} 小时</span>
        </div>
        <div class="payment_Price endpayment_Price">
          <span>用工人数</span>
          <span class="Price">{{ jd_people_min }} 人</span>
        </div>
        <div class="payment_Price">
          <span>工资</span>
          <span class="Price">¥{{ jd_salary }}</span>
        </div>
        <div class="payment_Price endpayment_Price">
          <span>服务费</span>
          <span class="Price">¥{{ service_charge }}</span>
        </div>
        <div class="payment_Price end_style">
          <span>总费用</span>
          <span class="Price"
            >¥{{ Number(all_jd_salary) + Number(deduction_odd_salary) }}</span
          >
        </div>
        <div class="payment_Price  " style="line-height:28px;"> 
          <span>抵扣费用</span>
          <span class="Price">¥{{ deduction_odd_salary }}</span>
        </div>
        <div class="  endpayment_Price" style="margin: 0 20px;font-size:12px;color:red;padding-bottom:10px;">*若不支付订单，抵扣费用仍会从我的钱包扣除，删除订单或订单24小时自动取消后，抵扣费用会返还至我的钱包</div>
        <div class="payment_Price end_style">
          <span>应付费用</span>
          <span class="Price">¥{{ all_jd_salary }}</span>
        </div>
        <div class="ewm_title" v-if="ewmimg">扫码支付</div>
        <div class="ewm" v-if="ewmimg">
          <img :src="ewmimg" alt="" />
        </div>
        <div class="ispayment" v-if="ewmimg == ''">
          <el-button
            type="warning"
            plain
            style="width: 70px; margin: 20px"
            @click="okpayment"
            >确认支付</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog title="提示"  :visible.sync="dialogVisible" width="30%" :show-close="false">
      <span style="font-size:16px;display:block;">是否确认下单</span>
      <span slot="footer" class="dialog-footer">
        <el-button style="float: inherit;background-color:#eee !important;color:#000; " @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button style="float: inherit;border: 1px solid #DCDFE6;" type="primary" @click="okpayment"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { number } from "echarts";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapMutations, mapState } from "vuex";
// 转二维码
import QRCode from "qrcode";

import {
  jd_create_time,
  wx_native_order,
  native_order_query,
  jd_pay,
  delApi,
} from "@/api/tool/publish";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    jobList: {
      type: Array,
    },
    activeName: {
      type: String,
    },
    addto: {
      type: Boolean,
    },
  },
  data() {
    //这里存放数据
    return {
      loading: false,
      dialogVisible: false,
      isOddjob: 0,
      // 岗位薪资
      jd_salary_min: 0,
      // 时长
      all_work_hours: 0,
      // 用工人数
      jd_people_min: 0,
      // 应付费用
      all_jd_salary: 0,
      // 抵扣费用
      deduction_odd_salary: 0,
      // 服务费
      service_charge: 0,
      // 工资
      jd_salary: 0,
      // 支付显示
      is_payment: false,
      // id
      jd_id: "",
      // 订单详情
      order: {},
      // 二维码
      ewmimg: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    ...mapMutations({
      emitPublishForm: "message/emitPublishForm",
    }),

    // 确认支付
    okpayment() {
      this.loading = true;
      this.jd_pay();
      this.dialogVisible = false;
    },

    // 删除
    is_delete(item) {
      delApi({ jd_id: item.id }).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg);
          this.$parent.getJD();
        }
      });
      console.log(item.id);
    },

    // 查看详情
    details() {},

    // 再次下单
    again(item) {
      var val = item;
      this.$parent.open_agreement(val);
      // this.publishForm(val)
    },

    // 支付
    payment(item) {
      console.log(item);
      this.jd_id = item.jd_id;
      this.all_jd_salary = item.pay_charge;
      this.deduction_odd_salary = item.deduction_odd_salary;
      this.jd_salary = item.jd_salary;
      this.jd_salary_min = item.jd_salary_min;
      this.jd_people_min = item.jd_people_min;
      this.all_work_hours = item.all_work_hours;
      this.service_charge = item.service_charge;
      this.wx_native_order();
    },

    // 支付订单
    async wx_native_order() {
      this.ewmimg = "";
      this.loading = true;
      this.is_payment = true;
      //订单提交
      await wx_native_order({
        total_fee: Number(this.all_jd_salary * 100).toFixed(0),
        body: "零工需求发布",
        jd_id: this.jd_id,
      }).then((res) => {
        if (!res.code) {
          this.order = res.data;
          var _this = this;
          QRCode.toDataURL(res.data.code_url, function (arr, url) {
            _this.ewmimg = url;
          });
          this.loading = false;
          // 定时器
          // 轮询查询结果
          this.timer = setInterval(this.native_order_query, 2000);
        } else if (res.code == 203016) {
          this.loading = false;
        }
      });
    },
    // 轮询查询结果
    native_order_query() {
      native_order_query({
        out_trade_no: this.order.out_trade_no,
        nonce_str: this.order.nonce_str,
      }).then((res) => {
        if (!res.code) {
          this.$message.success("支付成功");
          this.jd_pay();
          clearInterval(this.timer);
        }
      });
    },
    // 支付成功
    jd_pay() {
      jd_pay({ jd_id: this.jd_id }).then((res) => {
        if (!res.code) {
          this.is_payment = false;
          this.$message.success(res.msg);
          this.$parent.getJD();
        }
      });
    },

    // 关闭订单
    close_order() {
      this.is_payment = false;
      clearInterval(this.timer);
    },

    //灰朦罩子
    publishForm(item, index) {
      if (index) {
        this.$parent.is_details = index;
      }
      jd_create_time({ jd_id: item.jd_id }).then((res) => {
        if (!res.code) {
          this.$emit("jdcreatetime", res.data.jd_time);
        }
      });
      // 修改父组件的值
      this.$emit("isaddto", false);
      if (sessionStorage.getItem("vip_expire") == 1) {
        this.$message({
          message: "账号会员时间已过，该功能无法使用",
          type: "warning",
        });
        return;
      }
      if (item.jd_hide == 0 && item.jd_status == 1) {
        this.$message({
          message: "请稍等，正在审核",
          type: "warning",
        });
      } else if (item.is_odd_job == 1) {
        this.$emit("echo", item);
      } else {
        this.emitPublishForm({ isPublishForm: true, info: item });
      }
    },
    //关闭
    closeHandle(item) {
      if (sessionStorage.getItem("vip_expire") == 1) {
        this.$message({
          message: "账号会员时间已过，该功能无法使用",
          type: "warning",
        });
        return;
      }
      console.log(item);
      if (item.jd_hide == 0 && item.jd_status == 1) {
        this.$message({
          message: "请稍等，正在审核",
          type: "warning",
        });
      } else {
        this.$emit("closeHandle", item);
      }
    },
    //删除
    detele(item) {
      if (sessionStorage.getItem("vip_expire") == 1) {
        this.$message({
          message: "账号会员时间已过，该功能无法使用",
          type: "warning",
        });
        return;
      }
      this.$emit("deteleHandle", item);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (sessionStorage.getItem("odd_job")) {
      this.isOddjob = sessionStorage.getItem("odd_job");
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss"  scoped>
/* //@import url(); 引入公共css类 */

.main_content {
  position: relative;
  width: 1100px;
  height: 118px;
  background-color: #fff;
  /* margin-top: 14px; */
  /* margin-bottom: 20px; */
  border-bottom: 1px solid #f6f6f6;
  transition: all 1.6s;
}
.odd_status {
  position: absolute;
  right: 0;
  top: 0;
  height: 78px;
  width: 78px;
  margin-top: 0 !important;
}
.odd_status img {
  height: 100%;
  width: 100%;
}

/*  */
.shangxiajia {
  text-align: center;
  margin-top: 150px;
  color: #ccc;
}
.show {
  float: left;
  margin-left: 30px;
  clear: both;
}
.show p {
  font-size: 18px;
  color: #414a60;
}
.show p span {
  color: #8d92a1;
  line-height: 21px;
  font-size: 13px;
  margin-right: 5px;
}
.operation {
  float: right;
  width: 200px;
  height: 118px;
  margin-right: 20px;
}
.operation > div {
  margin-top: 30px;
}
.operation > div:first-child > span {
  margin-right: 20px;
  font-size: 14px;
  color: $main_color;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.operation > div:last-child {
  // margin-top: 30px;
  float: right;
}
.operation > div:last-child > p {
  float: left;
  color: #8d92a1;
  line-height: 20px;
  font-size: 16px;
  margin-right: 12px;
}

.operation_odd {
  display: flex;
  align-items: center;
  float: right;
  // width: 200px;
  height: 118px;
  margin-right: 80px;
}
.operation_odd > div {
  margin-top: 30px;
}
.operation_odd > .continue > span {
  margin-right: 20px;
  font-size: 14px;
  color: $main_color;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 10px;
  border: 1px solid #ff9900;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.operation_odd > div:last-child > p {
  line-height: 20px;
  font-size: 16px;
  margin-right: 12px;
}
.linear-enter,
.linear-leave-to {
  opacity: 0;
  transform: translateY(80px);
}

.linear-enter-active,
.linear-leave-active {
  transition: all 0.2s ease;
}

/* 下面的 .v-move 和 .v-leave-active 配合使用， 能够实现列表后续的元素， 渐渐地飘上来的结果 */
.linear-move {
  transition: all 0.2s ease;
}

.linear-leave-active {
  position: absolute;
}
//关闭
.close {
  padding-left: 30px;
}
i {
  padding: 0 7px;
  &::before {
    content: "|";
    line-height: 21px;
  }
}

.payment_wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
}
.payment_content {
  background-color: #fff;
  width: 500px;
}
.payment_title {
  background-color: #ebebeb;
  padding: 0 20px;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment_title .svg-icon {
  cursor: pointer;
}
.payment_Price {
  margin: 0 20px;
  line-height: 40px;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}
.payment_Price .Price {
  font-size: 14px;
  color: #000;
}
.endpayment_Price {
  border-bottom: 1px solid #414a60;
}
.end_style .Price {
  font-size: 20px !important;
  color: #f00 !important;
}
.ewm_title {
  line-height: 40px;
  padding: 0 20px;
  font-size: 14px;
}
.ewm {
  width: 300px;
  height: 300px;
  margin: 10px auto 30px;
}
.ewm img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.view_details {
  padding: 4px 10px;
  margin-left: 20px;
  font-size: 14px;
  color: #409eff !important;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  background-color: #ecf5ff;
  cursor: pointer;
}
.ispayment {
  display: flex;
  justify-content: flex-end;
}
</style>