<!-- 发布职位 -->
<template>
  <div class="wrap" v-loading="loading">
    <div class="fixed">
      <el-button class="btn" @click="publishForm" v-if="!isOddjob">
        <i class="el-icon-plus"></i>
        <span>添加职位</span>
      </el-button>
      <el-button
        v-else
        class="btn"
        style="width: 140px"
        @click="open_agreement('')"
      >
        <i class="el-icon-plus"></i>
        <span>发布零工需求</span>
      </el-button>
      <!-- <div class="search_wrap">
        <el-input placeholder="请输入职位名称" disabled>
          <i slot="suffix" class="el-icon-search search" @click="search"></i>
        </el-input>
      </div>-->
      <br />
      <div class="public-style">
        <el-tabs v-model="activeName" @tab-click="getJD" v-if="!isOddjob">
          <el-tab-pane label="上架" name="first"> </el-tab-pane>
          <el-tab-pane label="下架" name="second"> </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="position">
      <PublishJobComponent
        ref="PublishJobs"
        @echo="echo"
        @deteleHandle="deteleHandler($event)"
        @closeHandle="closeHandler($event)"
        @isaddto="isaddto"
        @jdcreatetime="jdcreatetime"
        :jobList="jobList"
        :addto="addto"
        :activeName="activeName"
      ></PublishJobComponent>
    </div>

    <!-- 添加零工 -->
    <div class="oddjob_wrap" v-if="oddjobShow">
      <div class="content_wrap">
        <div class="text_wrap">
          <div class="text_wrap_close" @click="empty" v-if="is_details">
            <svg-icon iconClass="close"></svg-icon>
          </div>
          <div class="text_submit" @click="form_submit" v-if="!is_details">
            发布
          </div>
          <div class="text_exit" @click="empty" v-if="!is_details">取消</div>
          <div class="form_wrap">
            <div>
              <el-form>
                <el-form-item label="职位标题" required>
                  <el-input
                    maxlength="10"
                    v-model="name"
                    :disabled="!addto"
                    placeholder="请输入职位"
                  ></el-input>
                </el-form-item>

                <el-form-item label="计薪方式" required>
                  <el-radio v-model="Payroll" :disabled="!addto" :label="0"
                    >小时</el-radio
                  >
                </el-form-item>

                <!-- <el-form-item label="结算方式" required>
                  <el-select
                    v-model="value2"
                    :disabled="true"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in Settlement"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item> -->

                <el-form-item label="工作地点" required>
                  <el-cascader
                    :options="cn"
                    @change="getcity_level"
                    :disabled="!addto"
                    v-model="arrCnid"
                  ></el-cascader>
                </el-form-item>

                <el-form-item
                  label="详细地址"
                  style="position: relative"
                  required
                >
                  <el-input
                    @blur="Address_detection"
                    :disabled="!addto"
                    v-model="detailedaddress"
                  ></el-input>
                  <div style="position: absolute; font-size: 12px; color: red;line-height: normal;left:17%;">
                    该地址将用于零工打卡，请精准到门牌号!
                  </div>
                </el-form-item>
                <el-form-item label="职位描述" required>
                  <el-input
                    type="textarea"
                    class="area"
                    placeholder="请填写职位描述"
                    maxlength="500"
                    :disabled="!addto"
                    v-model="jobdescription"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <div class="right_div">
                  <el-form-item label="性别" required>
                    <el-radio v-model="jd_gender" :disabled="!addto" :label="0"
                      >不限</el-radio
                    >
                    <el-radio v-model="jd_gender" :disabled="!addto" :label="1"
                      >男</el-radio
                    >
                    <el-radio v-model="jd_gender" :disabled="!addto" :label="2"
                      >女</el-radio
                    >
                  </el-form-item>

                  <el-form-item label="是否需要健康证" required>
                    <el-switch
                      @change="switch_jd_health"
                      v-model="jd_health"
                      active-text="是"
                      :disabled="!addto"
                      inactive-text="否"
                      active-value="1"
                      inactive-value="0"
                    >
                    </el-switch>
                  </el-form-item>
                </div>

                <el-form-item label="年龄限制" required class="form_age">
                  <el-input
                    placeholder="最小年龄"
                    v-model="jd_age_min"
                    maxlength="2"
                    :disabled="!addto"
                    @change="age_judge"
                    oninput="value = value.replace(/[^\d]/g, '');"
                  >
                  </el-input>
                  <span style="width: 30px; text-align: center">--</span>
                  <el-input
                    placeholder="最大年龄"
                    v-model="jd_age_max"
                    :disabled="!addto"
                    @change="age_judge"
                    maxlength="2"
                    oninput="value = value.replace(/[^\d]/g, '');"
                  >
                  </el-input>
                </el-form-item>

                <el-form-item label="工作时段" required>
                  <div class="shiduan">
                    <el-time-select
                      placeholder="起始时间"
                      :clearable="false"
                      v-model="startTime"
                      :disabled="!addto"
                      @change="time_judge"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00',
                      }"
                    >
                    </el-time-select>
                    <div>--</div>
                    <el-time-select
                      :clearable="false"
                      placeholder="结束时间"
                      @change="time_judge"
                      v-model="endTime"
                      :disabled="!addto"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00',
                      }"
                    >
                    </el-time-select>
                  </div>
                </el-form-item>

                <el-form-item
                  class="Job-skills"
                  label="职位技能 (1-6个) (最多10个字)"
                  required
                >
                  <br />
                  <el-tag
                    :key="tag"
                    v-for="tag in dynamicTags"
                    :closable="addto"
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    maxlength="10"
                    show-word-limit
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="showInput"
                    :disabled="!addto"
                    >添加技能</el-button
                  >
                </el-form-item>

                <el-form-item label="学科要求" required v-if="odd_job_status == 2">
                  <el-cascader
                    :options="show_subject_json_data"
                    @change="jd_subject_fun"
                    :disabled="!addto"
                    v-model="jd_subject"
                  ></el-cascader>
                </el-form-item>


                <el-form-item label="工作日期" required>
                  <div style="float: left">
                    {{ dateTime }}
                  </div>
                  <el-popover placement="right" width="400" trigger="click">
                    <div>
                      <div
                        class="calendar"
                        v-if="JSON.stringify(this_month) != '{}'"
                      >
                        <div class="timetop">
                          <div @click="preTime" style="cursor: pointer">
                            <svg-icon iconClass="shangyiye"></svg-icon>
                          </div>
                          <div class="timetop_title">
                            {{ this_month[0].time }}
                          </div>
                          <div @click="nextTime" style="cursor: pointer">
                            <svg-icon iconClass="xiayiye"></svg-icon>
                          </div>
                        </div>
                        <div class="week">
                          <div v-for="(item, index) in week" :key="index">
                            {{ item }}
                          </div>
                        </div>
                        <div>
                          <div class="time_list">
                            <div
                              class="itemlist_div"
                              v-for="(item, index) in this_month[0]
                                .item_children"
                              :key="index"
                            >
                              <div v-if="addto">
                                <div
                                  class="old_day"
                                  v-if="
                                    Date.parse(new Date()) + 604800000 >
                                    Date.parse(
                                      item.item_title + '-' + item.item_text
                                    )
                                  "
                                >
                                  {{ item.item_text }}
                                </div>
                                <div
                                  v-else
                                  @click="new_catdate(item)"
                                  :class="{
                                    TimeSelect:
                                      dateTime ==
                                      item.item_title + '-' + item.item_text,
                                  }"
                                >
                                  {{ item.item_text }}
                                </div>
                              </div>
                              <div v-else>
                                <div
                                  class="old_day"
                                  v-if="
                                    Date.parse(new Date()) >
                                      Date.parse(
                                        item.item_title + '-' + item.item_text
                                      ) ||
                                    Number(jdcreatetimedata) + 604800000 >
                                      Date.parse(
                                        item.item_title + '-' + item.item_text
                                      )
                                  "
                                >
                                  {{ item.item_text }}
                                </div>
                                <div
                                  v-else
                                  @click="new_catdate(item)"
                                  :class="{
                                    TimeSelect:
                                      dateTime ==
                                      item.item_title + '-' + item.item_text,
                                  }"
                                >
                                  {{ item.item_text }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <el-button
                      slot="reference"
                      style="margin-left: 20px"
                      @click="timeFn"
                      :disabled="is_details == 1"
                      >选择开始时间</el-button
                    >
                  </el-popover>
                </el-form-item>

                <el-form-item label="工作天数" required>
                  <el-select
                    v-model="working_days"
                    @change="Workdays_fn"
                    placeholder="请选择"
                    :disabled="is_details == 1"
                  >
                    <el-option
                      v-for="item in work_days_arr"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="用工人数" class="recruits" required>
                  <el-input
                    v-model.trim="recruits"
                    :disabled="is_details == 1"
                    placeholder="请输入用工人数"
                    ><template slot="append">人</template></el-input
                  >
                </el-form-item>

                <el-form-item label="岗位时薪" required>
                  <el-input
                    type="number"
                    @input="testing_city_level"
                    v-model.trim="post_salary"
                    placeholder="请输入岗位时薪"
                    :disabled="is_details == 1"
                    ><template slot="append">元</template></el-input
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 客户服务协议 -->
    <div class="agreement_wrap" v-if="isagreement">
      <div class="agreement">
        <div class="text">
          <div class="close" @click="close_agreement">
            <svg-icon iconClass="close"></svg-icon>
          </div>
          <h4>24猎零工企业客户服务协议</h4>
          <div class="tips">
            本协议是入驻24猎及24猎关联平台发布项目信息的主体(以下简称“企业客户”或“甲方”)与北京三门课科技有限公司(以下简称“乙方”或“24猎”)就企业客户使用24猎平台相关服务签署的协议。<br />
            <h3>服务内容及服务标准</h3>
            1、协议有效期：自甲方下单之日起至订单结束时止。<br />
            2、服务内容：甲方具有发布生产经营服务任务满足自身生产经营活动的需求，乙方具有共享信息服务平台，有大量零工入驻乙方平台。平台可通过智能匹配人力为甲方提供相关服务，满足甲方生产经营需求。<br />
            3、计费标准及说明<br />
            3.1甲方应向乙方支付的平台费用的计费标准以甲方发布的任务需求为标准。小时单价以乙方要求城市下限标准为准。乙方可对其提供服务的平台费用单方进行调整，甲方会在下单时收到关于费用调整的提示。<br />
            3.2甲方向乙方支付的平台费用中，包含乙方代收的甲方应向零工给付的生产经营服务费用。甲方应足额向乙方支付生产经营服务费用，并同意由乙方代收后转付给相应零工。<br />
            3.3计费标准：以甲方下单时实际价格为准(下单时显示)。<br />
            4、平台费用<br />
            4.1平台费用金额以订单金额作为依据，用工时长、用工人数等以乙方平台系统记载为准。<br />
            4.2费用异议：若甲方对订单金额数据存在异议，应在每笔订单完成后3个工作日内向乙方/乙方关联方/乙方合作方提出书面数据核对要求，由双方共同核对并确认最终费用数据，如无法核实的，则以乙方/乙方关联方/乙方合作方提供数据为准。甲方逾期未提出异议的或未提供合理有效的其他费用数据的，视为甲方认可乙方/乙方关联方/乙方合作方数据。<br /><br />

            <h3>通用条款</h3>
            为了充分发挥网络信息优势，构建基于互联网平台的共享服务产业，实现双赢、共同发展的目的，经甲乙双方友好协商，现就乙方/乙方关联方/乙方合作方向甲方提供生产经营服务等事宜达成如下协议，以资双方共同信守。<br />
            一、总则<br />
            1.1本协议为企业客户(以下所称甲方)与北京三门课科技有限公司及其关联公司及合作公司(以下统称乙方)就企业客户使用24猎及24猎相关平台服务签署的协议。<br />
            1.2若您对本协议条款有任何疑问，请咨询24猎业务人员。若您不同意本协议任何条款或无法准确理解条款含义的，请您不要进行后续操作。若您继续进行账号申请使用或其他使用乙方服务的行为则表明您自愿接受本协议的全部内容并受其约束，您不得以任何理由包括但不限于未能认真阅读本协议作为抗辩理由。<br />
            1.3本协议会因监管法律法规的更新及24猎服务性质和内容的不断发展而调整，甲方同意24猎有权对本协议条款以在线更新并通过24猎平台公告的方式进行修改，甲方应及时查看相关公示信息。<br />
            1.4由于互联网高速发展，甲方与乙方的合作协议所列明的条款并不能完整罗列并覆盖企业客户与24猎所有的权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，24猎平台法律隐私政策、平台规则、甲方与乙方的任一平台合作协议均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如甲方使用24猎提供的服务，视为甲方同意上述补充协议。<br /><br />

            二、定义<br />
            2.1乙方平台：指包括但不限于域名为www.hr24.com.cn的24猎官方网站、hrswork.com的猎零云管理后台和以三门课名义运营的名称为“24猎企业版”的移动端应用程序(微信小程序)，以及与此相关的微信公众账号等已有的及将来可能新设立的其他平台的单称或合称。<br />
            2.2关联方：指相互间直接或间接持有其中一方的股权总和达到25%或以上的；或者直接或问接同为第三者所拥有或控制股权达到25%或以上的；或者对自身生产经营、交易具有实际控制的与其他利益相关联的关系(包括但不限于婚姻、近亲属关系；股权代持关系；对董事会拥有控制权等)。<br />
            2.3乙方平台规则：指展示在乙方平台上的，甲方需实时关注的与甲方经营有关的任何已经发布及后续发布的全部规则、实施细则、产品流程说明、公告等内容，具体以乙方平台实际展示的内容为准。<br />
            2.4平台费用：指甲方使用乙方平台相关服务，应向乙方/乙方关联方/乙方合作方支付的费用。平台费用包含甲方应向零工给付的生产经营服务费用，该部分费用由乙方代收后转付至相应零工。<br />
            2.5零工：指在乙方平台上授权注册，通过乙方平台接受甲方任务，为甲方临时提供生产经营活动并获得服务费的个体(包括个人及个体工商户)。<br /><br />

            三、服务内容<br />
            3.1甲方需要委托乙方/关联方/合作方为其提供匹配服务，推荐零工提供生产经营活动满足甲方的服务经营需求。<br />
            3.2乙方具备共享经济资源，可为甲方提供零工匹配服务。<br />
            3.3其他服务：除上述服务外，乙方/乙方关联方/乙方合作方会根据业务发展情况不时开发新的产品和功能，甲方可选择是否使用新的产品或功能，若甲方选择使用新产品或功能，需按照协议的约定支付相应的服务费用。<br />
            3.4乙方仅为甲方提供零工匹配服务，甲方及零工分别为入驻乙方平台的企业客户及个人用户。甲方与乙方之间、及乙方与零工之间，均不因本协议的存在而成立劳动关系、劳务关系、雇佣关系、或代理关系等非平台服务合同关系。<br />
            3.5甲方需按照平台系统指示进行用工需求下单。若需延长用工时间或扩大用工人数的，应另行下单。<br />
            3.6除双方另有约定外，零工在提供生产经营活动期间，由乙方与零工协商缴纳商业保险事宜，保费由乙方承担。若保险缴费、事故勘察、理赔等相关事宜需甲方履行提供材料、说明等配合义务的甲方应予配合。若甲方不予配合，所造成的保险拒赔后果，由甲方承担赔偿责任。<br />
            3.7对于因甲方故意或重大过失导致零工在提供生产经营活动过程中受到人身及财产损害，或给他人造成损失的，由保险公司先行赔付，保险未予赔偿的部分，由甲方承担责任。非因甲方故意或重大过失，零工在在提供生产经营活动过程中受到人身及财产损害，或给他人造成损失的，保险未予赔偿的部分与甲方无关，由乙方根据损害原因等情况与零工协商责任划分。<br /><br />

            四、各项费用计费标准<br />
            4.1甲方应向乙方支付平台费用。每笔订单支付费用包含零工生产经营服务费用和平台服务费。支付的平台费用不退，生产经营服务费用部分在零工完成上岗后进行扣除。<br />
            4.2甲方需依照下单时小程序显示的价格足额给付平台费用，发票类目为服务费。<br /><br />

            五、甲方权利与义务<br />
            5.1甲方有权根据本协议及“24猎”平台使用规则，合法使用“24猎”平台发布项目需求信息、查询甲方信息参加“24猎”平台有关活动并享受“24猎”平台提供的其他有关资讯及信息服务。<br />
            5.2甲方有义务确保在“24猎”平台上发布的项目信息真实准确无误导性，确保发布的项目及委托乙方提供的服务内容不违反国家法律、法规、行政规章的规定、不侵犯他人知识产权或其他合法权益、不违背社会公共利益或公共道德、不违反“24猎”平台的相关规定。如有违反导致的任何法律后果，或甲方使用“24猎”平台发生的任何商业行为所引起的一切法律责任，均由甲方自行承担，与乙方无关。但乙方可积极配合甲方处理相关事宜以促进各项事宜的解决。<br />
            5.3如果甲方发布的项目或委托乙方提供的服务内容需要核验登记乙方及乙方人员的信息、或需要获得国家有关部门的认可、批准或相关合法资格的，甲方承诺其具有运营本项目所需的全部合法且适格的资格与资质，并同意按乙方要求提供核验登记所需的相应资格资质证明的复印件(包括但不限于营业执照副本、食品经营许可证件、授权人员身份证件、授权委托书等)作为合同附件。否则，乙方有权拒绝提供相关服务并根据具体情况决定是否解除本协议。<br />
            5.4甲方对发布的项目信息、审核标准等内容及形式应自行审核。除本协议另有约定除外，乙方有权检查甲方发布项目及要求乙方提供的服务内容，对违反法律法规、侵害他人合法权益或对他人合法权益产生威胁的项目或服务内容，或甲方利用乙方提供的服务从事违反国家法律法规及有关部门监管规定活动的，乙方有权采取包括但不限于以下措施中的一项或多项：有权删除或临时中止甲方发布项目，并有权要求甲方提供合理说明；拒绝提供甲方委托的服务内容；立即删除并对甲方进行封号处理；有权立即解除本协议并要求甲方赔偿乙方由此遭受的全部损失，对于甲方已经支付的平台费用，乙方不予退还。<br />
            5.5甲方应限于自身或关联方使用乙方提供的产品/服务，不得直接、间接或诱使其他第三方从事与乙方有竞争关系的业务，同时不得将乙方提供的产品/服务以任何方式转服务(转售或者二次销售)于第三方。<br />
            5.6甲方不得利用乙方平台从事违反法律及行政法规等行为(包括但不限于涉嫌洗钱、偷税、漏税、逃税、贿赂等)或/及其他不当使用乙方服务的行为。甲方存在上述行为的，乙方有权立即终止向甲方提供服务，并有权追偿因此给乙方造成的损失。<br />
            5.7甲方有权依据平台规则注册帐号并使用该号为甲方唯一对应账号。甲方应妥善管理帐号密码，不得将账号密码出借或提供给他人。除有明确证据证明密码被盗外，任何甲方所注册账号下的行为、指令，操作等均视同为甲方意思表示，对甲方产生合同效力。<br /><br />

            六、乙方权利与义务<br />
            6.1乙方仅为甲方提供一个开放式信息发布平台，为项目的发布与解决提供配套的管理工具及信息中介服务。乙方负责“24”平台运营、维护，确保平台上甲方项目订单发布、项目订单审核、费用支付等操作流程的正常运行。<br />
            6.2乙方有权对甲方的发布的项目或委托服务内容进行审查、登记、备案，乙方有权要求甲方按照乙方的要求进行核查，甲方应当配合并按照甲方的要求予以改正；乙方的上述审核行为并不意味着乙方对甲方的经营资质、任务、交易或甲方自行上传在“24猎”平台的其他信息的真实性、准确性、合法性、有效性承担任何明示或暗示的保证或担保等责任。<br />
            6.3乙方就本协议项下提供的共享经济综合服务有权向甲方收取相应的平台服务费，乙方应当本着甲方利益最大化的原则，勤勉履行本协议，维护甲方形象，不得损害甲方的合法权益。<br />
            6.4甲方与乙方之间，均不因本协议的存在而成立劳动关系、劳务关系、雇佣关系、或代理关系等非平台服务合同关系。<br />
            6.5以下情况不应视为乙方违约，乙方不承担任何责任：<br />
            （1）甲方的钱包的余额、或可用资金额度不足；<br />
            （2）甲方未按照乙方或“24猎”平台的有关平台规则、流程规范等要求操作与执行;<br />
            （3）不可抗力或其他不可归因于乙方的情形，包括但不限于网络故障、通讯故障、停电、黑客或病毒侵袭等;<br />
            （4）有关部门或国家机关依法命令乙方停止提供服务或停止为甲方服务；<br />
            （5）因“24猎”平台或甲方的相关系统升级、调试等原因，需要按计划暂停服务时，乙方提前发布了升级、调试声明，或已提前通知甲方的。<br /><br />

            七、协议终止与解除<br />
            7.1出现以下情形之一的，乙方可以随时解除本协议，并有权要求甲方承担相应违约责任，赔偿相应损失：<br />
            （1）甲方通过乙方服务从事违反法律法规、侵害他人合法权益或严重损害乙方的利益的活动的；<br />
            （2）因甲方的宣传过失给乙方造成严重负面影响，致使乙方社会评价严重下降的；<br />
            （3）甲方(含关联方)直接、间接或诱使其他第三方从事与乙方有竞争关系的业务或擅自将合作产品/服务转服务(转售或者二次销售)于第三方的；<br />
            （4）甲方破产或被取消营业资格的；<br />
            （5）甲方擅自聘用乙方平台上的零工，绕开乙方与零工建立兼职或全职劳务用工性质的法律关系的(包括但不限于签订劳动合同劳务合同、或合作合同等)，乙方有权要求甲方承担违约责任，并要求甲方赔偿因此给乙方造成的一切损失；<br /><br />

            八、保密条款<br />
            8.1甲乙双方对于本协议的签订、内容及在履行本协议期间所获知的另一方的商业秘密负有保密义务。非经对方书面同意，任何一方不得向第三方(关联公司除外)泄露、给予或转让该等保密信息。根据法律、法规、证券交易所规则向政府、证券交易所或其他政府机构提供，或基于自身必要需求向各自的法律、会计、商业及其他顾问、雇员提供除外。<br />
            8.2如对方提出要求，任何一方均应将载有对方保密信息的任何文件、资料或软件等，在本协议终止后按对方要求归还对方，或予以销毁，或进行其他处置，并且不得继续使用这些保密信息。<br />
            8.3保密期限应为：自协议生效之日起到协议因各种原因解除或终止以后十年。<br /><br />

            九、通知及送达<br />
            9.1除本协议另有约定外，在履行本协议过程中的一切通知，双方均可通过网页公告、系统通知、微信提醒或其他合理方式进行，该等通知于发送之日视为已送达收件人。
            <br /><br />

            十、违约责任<br />
            10.1除本协议另有约定外，本协议任意一方不履行或不完全履行本协议约定的义务和承诺即构成违约。违约方应当负责赔偿其违约行为给守约方造成的一切经济损失(包括但不限于合理的律师费、诉讼费、公证费等)。<br />
            10.2本协议关于违约状态下的救济方法(包括但不限于要求承担违约责任、赔偿责任和解除协议等)的条款是累加的，可以选择适用或同时适用。<br />
            10.3如果甲方擅自联系乙方平台上的零工，绕开乙方与零工建立兼职或全职劳务用工性质的法律关系的(包括但不限于签订劳动合同、劳务合同、或合作合同等)每挖角签约一人，应向乙方支付5000元的违约金，违约金不足以弥补乙方损失的，甲方应赔偿实际造成的全部损失。<br />
            10.4若任意一方违背了本协议中的任何约定，任意一方在向另一方出具书面违约提醒要求违约方履行协议或采取必要的经济补救措施后的30个自然日(含)，未收到违约方书面回应的，守约方除了在法律或资产方面向违约方主张赔偿外还有权终止本协议。<br />
            10.5因政府行为(包括但不限于因中国政府机关不授予或取消甲方、乙方相应经营资质、权利)或其他不可抗力因素导致本协议部分条款或全部条款无法履行的，遭受该行为的一方不承担违约责任。<br /><br />

            十一、适用法律和争议解决<br />
            11.1本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。凡因本协议引起的或与本协议有关的任何争议，双方应友好协商解决。如不能协商解决，双方一致同意提交至乙方所在地有管辖权的人民法院诉讼解决。<br /><br />

            十二、权利的行使<br />
            12.1本协议的任意一方或双方未能及时行使本协议项下的权利不应被视为放弃该权利，也不影响该方权利。<br /><br />
          </div>
        </div>
        <div class="isok">
          <div class="ok_button" @click="agreement__isok">我已阅读并同意</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import PublishJobComponent from "@/components/publishJob";
import { mapMutations, mapState, mapActions, Store } from "vuex";
import { getCompanyShow, show_subject_json } from "./../../api/eliteApi/index";
import {
  gettrigger,
  delApi,
  getPublish,
  place_location,
  getUpdate,
  odd_jd_data,
  jd_workday,
} from "@/api/tool/publish";
import { showCity } from "@/api/me/index.js";

import { company_scale } from "./../../utils/searchtype";
import { number } from "echarts";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { PublishJobComponent },
  data() {
    //这里存放数据
    return {
      loading: false,
      // 是否为零工账号
      isOddjob: false,

      // 是新添加还是继续
      addto: true, //true是新添加 false是继续添加

      activeName: "first",

      // 是否显示用户协议
      isagreement: false,
      // 发布职位数据
      jobList: [],
      //状态
      command: "全部",
      statusAction: false,
      isBtn: true,

      // 零工显示
      oddjobShow: false,
      // 职位标题
      name: "",

      // 是否是查看详情
      is_details: 0,

      // 计薪方式
      Payroll: 0,
      // 计薪方式
      jd_gender: 0,
      // 工作时间
      workDay: "",
      //工作地点
      cn: [],
      arrCnid: [],
      // 详细地点
      detailedaddress: "",
      // 职位描述
      jobdescription: "",
      // 职位薪资
      post_salary: "",
      // 工作时段
      startTime: "",
      endTime: "",
      // 职位技能
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      // 用工人数
      recruits: "",
      // 最小年龄
      jd_age_min: "",
      // 最大年龄
      jd_age_max: "",
      // 是否需要健康证
      jd_health: "1", //0否 1是
      // 地点是否允许
      place_location: 1,

      jd_id: 0,

      //当前年月日
      isyear: "",
      ismonth: "",
      isday: "",

      // 选择的时间
      SchedulingTime: {},
      // 旧的
      week: ["日", "一", "二", "三", "四", "五", "六"],
      time_frame: "", // 时间范围
      days: "", // 周的下标
      dateTime: "", // 日期时间
      // 新的
      Warehouse: {}, // 仓库
      this_month: {}, // 当前月份
      item_children: [],

      // 账号创建时间
      company_create_time: 0,

      // 根据这个值是否为空来判断是再次下单(对象)还是发布零工需求(空值)
      open_agreement_val: "",

      // 工作天数
      working_days: "",
      work_days_arr: [1, 2, 3, 4, 5, 6, 7],

      // 获取到城市的最低价格
      city_level: {},
      // 职位创建时间
      jdcreatetimedata: "",

      jd_parent_id: "", //如果是新建就传空 不是就传jd_id

      // 结算方式
      // value2: "0",
      // Settlement: [
      //   {
      //     label: "日结",
      //     value: "0",
      //   },
      //   {
      //     label: "周结",
      //     value: "1",
      //   },
      //   {
      //     label: "月结",
      //     value: "2",
      //   },
      // ],


      odd_job_status: 0, // 零工权限——id
      show_subject_json_data: [], // 学科数组
      jd_subject: [], // 学科
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      is_show: (state) => state.message.is_show,
    }),
  },
  //监控data中的数据变化
  watch: {
    is_show(newVal, oldVal) {
      console.log("cacacac走了");
      if (!newVal) {
        this.getJD();
      }
    },
  },
  //方法集合
  methods: {
    // 打开协议
    open_agreement(val) {
      console.log('val', val)
      this.open_agreement_val = val; //根据这个值是否为空来判断是再次下单(对象)还是发布零工需求(空值)
      this.isagreement = true;
    },
    // 关闭协议
    close_agreement() {
      this.isagreement = false;
    },
    // 阅读并且同意
    agreement__isok() {
      this.close_agreement();
      if (this.open_agreement_val == "") {
        this.oddjobForm();
      } else {
        this.$refs.PublishJobs.publishForm(this.open_agreement_val);
      }
    },
    // 切换是否需要健康证
    switch_jd_health() {
      // console.log(this.jd_health);
    },
    // 子级传值继续添加
    isaddto(e) {
      this.addto = false;
    },
    // 获取子集传的职位创建时间
    jdcreatetime(e) {
      this.jdcreatetimedata = e * 1000;
    },
    // 获取到是第几级城市
    getcity_level() {
      odd_jd_data().then((res) => {
        if (!res.code) {
          this.city_level = res.data.city_level;
          this.testing_city_levelfn();
          // console.log(this.city_level,this.post_salary)
        }
      });
      // 省市区
      var Provincial = this.cn.find((item) => {
        return item.value == this.arrCnid[0];
      });
      var urban = Provincial.children.find((item) => {
        return item.value == this.arrCnid[1];
      });
      var areas = urban.children.find((item) => {
        return item.value == this.arrCnid[2];
      });
      if (this.arrCnid[0] == this.arrCnid[1]) {
        this.detailedaddress = Provincial.label + areas.label;
      } else {
        this.detailedaddress = Provincial.label + urban.label + areas.label;
      }
    },
    // 学科选择器
    jd_subject_fun(e) {
      console.log(e)
    },

    // 最近时薪检测
    testing_city_level() {
      this.post_salary = this.post_salary.replace(/[^\d]/g, "");
      if (!this.addto) {
        odd_jd_data().then((res) => {
          if (!res.code) {
            this.city_level = res.data.city_level;
            this.testing_city_levelfn();
            // console.log(this.city_level,this.post_salary)
          }
        });
      }
      this.testing_city_levelfn();
    },
    testing_city_levelfn() {
      if (this.city_level[this.arrCnid[2]] != undefined) {
        if (Number(this.post_salary) < this.city_level[this.arrCnid[2]]) {
          this.$message.warning(
            "当前城市最低时薪为" + this.city_level[this.arrCnid[2]]
          );
          this.post_salary = this.city_level[this.arrCnid[2]];
        }
      }
    },
    // 判断年龄
    age_judge() {
      console.log("123");
      if (Number(this.jd_age_min) < 16) {
        this.jd_age_min = 16;
        this.$message.warning("最小年龄为16岁");
        return;
      }
      if (Number(this.jd_age_min) > Number(this.jd_age_max)) {
        this.$message.warning("最小年龄不能超过最大年龄");
        this.jd_age_max = Number(this.jd_age_min);
        return;
      }
    },
    // 时间判断
    time_judge() {
      if (
        this.startTime == this.endTime &&
        this.startTime != "" &&
        this.endTime != ""
      ) {
        this.$message.error("时间不可以相同");
        this.startTime = "";
        this.endTime = "";
        return;
      }

      if (
        this.dateTime != "" &&
        this.working_days != "" &&
        this.startTime != "" &&
        this.endTime != ""
      ) {
        // 14400000 为4个小时
        var time =
          Date.parse(this.dateTime + " " + this.startTime) -
          Date.parse(this.dateTime + " " + this.endTime);
        // if (Math.abs(time) > 14400000) {
        //   this.startTime = "";
        //   this.endTime = "";
        //   this.$message.error("每天时间不得超过4个小时");
        //   return;
        // }
        // 100800000 为28小时
        if (Math.abs(time) * this.working_days > 100800000) {
          this.dateTime = "";
          this.working_days = "";
          this.$message.error("每次发布用工总时长不得超过28小时");
          return;
        }
      }
    },
    // 回显
    echo(data) {
      // console.log('111111111111111111111111111111111111', data);
      place_location({ type: 1, message: data.jd_place_detail }).then((res) => {
        this.place_location = res.data.status;
      });
      // console.log(this.dateTime);
      // console.log(this.is_details);
      this.oddjobShow = true;
      this.jd_id = data.id;
      this.name = data.jd_title;
      this.jd_parent_id = data.jd_id;

      if(this.odd_job_status != 0){
        this.jd_subject = data.jd_subject.split('-');
      }

      console.log(this.jd_parent_id);
      if (this.is_details) {
        // 是否为查看详情
        this.dateTime = data.work_day.split(",")[0];
        this.working_days = data.work_day.split(",").length;
        this.post_salary = data.jd_salary_max;
        // this.value2 = String(data.clearing_form); //结算方式
        // this.works_time = data.work_day;
        this.recruits = data.jd_people_max; //人数
      }
      if (data.jd_place_data.province_code) {
        this.arrCnid = this.arrCnid.concat(
          data.jd_place_data.province_code,
          data.jd_place_data.city_code,
          data.jd_place_data.district_code
        );
      } else {
        this.arrCnid = this.arrCnid.concat(
          data.jd_place_data.city_code,
          data.jd_place_data.city_code,
          data.jd_place_data.district_code
        );
      }
      console.log(this.arrCnid);
      this.jobdescription = data.jd_desc;
      this.detailedaddress = data.jd_place_detail;

      this.jd_gender = data.jd_gender;
      this.jd_age_min = data.jd_age_min;
      this.jd_age_max = data.jd_age_max;
      this.jd_health = data.jd_health.toString();
      this.startTime = data.work_hour.split("-")[0];
      this.endTime = data.work_hour.split("-")[1];
      var dynamicTags = data.jd_skill.split(",");
      this.dynamicTags = dynamicTags;
      this.Payroll = 0;
    },

    oddjobForm() {
      this.addto = true;
      this.jd_id = 0;
      if (sessionStorage.getItem("vip_expire") == 1) {
        this.$message({
          message: "账号会员时间已过，该功能无法使用",
          type: "warning",
        });
        return;
      }
      // if (!this.isBtn) {
      //   this.$message.warning("JD上架数量已达上限");
      //   return false;
      // }

      this.oddjobShow = true;
    },

    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      if (this.dynamicTags.length >= 6) {
        this.$message({
          message: "职位技能只能添加6个",
          type: "warning",
        });
      } else {
        this.inputVisible = true;
        this.$nextTick((_) => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      }
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 地址检测
    Address_detection() {
      place_location({ type: 1, message: this.detailedaddress }).then((res) => {
        this.place_location = res.data.status;
      });
    },

    // 表单提交
    form_submit() {
      if (!this.dateTime) {
        this.$message.warning("请选择正确的开始时间");
        return;
      }
      if (!this.endTime) {
        this.$message.warning("请选择正确的工作时段");
        return;
      }
      if (!this.working_days) {
        this.$message.warning("请选择正确的工作天数");
        return;
      }
      var work_day = [];
      for (var i = 0; i < this.working_days; i++) {
        work_day.push(
          this.dateStr(
            new Date(
              Date.parse(this.dateTime + " " + this.startTime) + 86400000 * i
            )
          )
        );
      }
      var arrJoinString = this.dynamicTags.join();
      var obj = {
        jd_title: this.name,
        jd_desc: this.jobdescription,
        jd_salary_min: Number(this.post_salary),
        jd_salary_max: Number(this.post_salary),
        jd_place_id: this.arrCnid[2],
        jd_salary_type: "小时",
        is_odd_job: 1,
        clearing_form: 1,
        work_type: 1,
        work_day: work_day.toString(),
        // clearing_form: this.value2,
        jd_place_detail: this.detailedaddress,
        jd_people_max: Number(this.recruits),
        jd_people_min: Number(this.recruits),
        work_hour: this.startTime + "-" + this.endTime,
        jd_skill: arrJoinString,
        jd_gender: this.jd_gender,
        jd_age_min: this.jd_age_min,
        jd_age_max: this.jd_age_max,
        jd_health: this.jd_health,
        jd_parent_id: this.addto == true ? "" : this.jd_parent_id,
      };
      if( this.odd_job_status != 0){
        obj['jd_subject'] = this.jd_subject.join('-')
      }


      if (!this.name) {
        this.$message.warning("请输入职位标题");
      } else if (!this.post_salary) {
        this.$message.warning("请输入岗位时薪");
      } else if (!this.jobdescription) {
        this.$message.warning("请输入职位描述");
      } else if (!this.arrCnid) {
        this.$message.warning("请输入工作地点");
      } else if (!this.detailedaddress) {
        this.$message.warning("请输入详细地址");
      } else if (this.place_location != 0) {
        this.$message.warning("请输入正确的详细地址");
      } else if (!this.recruits) {
        this.$message.warning("请输入用工人数");
      } else if (this.dynamicTags.length == 0) {
        this.$message.warning("请添加职位技能");
      } else if (this.jd_subject.length && this.odd_job_status == 2){
        this.$message.warning("请选择学科要求");
      } else if (!this.jd_age_min || !this.jd_age_max) {
        this.$message.warning("请输入正确的年龄限制");
      } else {
        getPublish(obj).then((res) => {
          if (!res.code) {
            this.empty();
            console.log(res);
            this.$refs.PublishJobs.all_jd_salary = res.data.pay_charge;
            this.$refs.PublishJobs.deduction_odd_salary =
              res.data.deduction_odd_salary;
            this.$refs.PublishJobs.jd_id = res.data.jd_id;
            this.$refs.PublishJobs.jd_salary = res.data.all_jd_salary;
            this.$refs.PublishJobs.jd_people_min = res.data.jd_people;
            this.$refs.PublishJobs.all_work_hours = res.data.all_work_hours;
            this.$refs.PublishJobs.service_charge = res.data.service_charge;
            this.$refs.PublishJobs.jd_salary_min = res.data.jd_salary;

            this.$message.success("添加成功");
            this.getJD();
            this.$refs.PublishJobs.wx_native_order();
          }
        });
      }
    },
    empty() {
      this.is_details = 0;
      this.name = "";
      this.post_salary = "";
      this.jobdescription = "";
      this.arrCnid = [];
      this.dateTime = "";
      this.working_days = "";
      this.detailedaddress = "";
      this.startTime = "";
      this.endTime = "";
      this.dynamicTags = [];
      this.recruits = "";
      this.Payroll = 0;
      this.jd_gender = 0;
      this.oddjobShow = false;
      this.jd_age_min = "";
      this.jd_age_max = "";
      this.jd_health = 1;
      // this.value2 = "1";
    },
    getJD(val) {
      this.loading = true;
      if (sessionStorage.getItem("vip_expire") == 1) {
        this.$message({
          message: "账号会员时间已过，该功能无法使用",
          type: "warning",
        });
      }
      console.log(this.activeName);
      let obj = {
        models_info: "jd",
      };
      if (this.activeName == "first") {
        obj.jd_hide = 0;
      } else {
        obj.jd_hide = 1;
      }
      getCompanyShow(obj).then((res) => {
        if (!res.code) {
          this.isBtn = res.data.create_jd ? true : false;
          // this.show = res.data.jd_data;
          this.jobList = res.data.jd_data;

          if (this.jobList && this.jobList.length > 0) {
            this.jobList.filter((item, index, self) => {
              item.place_name =
                item.jd_place_data.city_name + item.jd_place_data.district_name;
              return item;
            });
          }
          this.is_showChange(true);
          this.loading = false;
        }
      });
    },
    search() {
      console.log("11");
    },
    ...mapMutations({
      emitPublishForm: "message/emitPublishForm",
      is_showChange: "message/is_showChange",
    }),

    //灰朦罩子
    publishForm() {
      if (sessionStorage.getItem("vip_expire") == 1) {
        this.$message({
          message: "账号会员时间已过，该功能无法使用",
          type: "warning",
        });
        return;
      }
      if (!this.isBtn) {
        this.$message.warning("JD上架数量已达上限");
        return false;
      }
      this.emitPublishForm({ isPublishForm: true });
    },
    //删除
    deteleHandler(e) {
      console.log(e);
      this.$confirm("是否删除该职位?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            jd_id: e.id,
          };
          delApi(obj).then((res) => {
            if (res.code === 0) {
              this.getJD();

              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //关闭
    closeHandler(e) {
      console.log(e);
      let obj = {};

      if (e.jd_hide === 1) {
        obj = {
          jd_id: e.id,
          jd_hide: 0,
        };
      } else if (e.jd_hide === 0) {
        obj = {
          jd_id: e.id,
          jd_hide: 1,
        };
      }
      console.log(obj);

      gettrigger(obj).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.getJD();
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
        if (res.code === 202010) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },

    // 点击显示时间
    timeFn() {
      this.formatTime();
      this.current_time_();
    },
    // // // 时间
    // 格式化时间
    formatTime() {
      let date = new Date();
      this.isyear = date.getFullYear();
      this.ismonth = date.getMonth() + 1;
      this.isday = date.getDate();
    },
    // 日历表
    current_time_() {
      let dateTime, this_month, Last_month, next_month;
      let yy = this.isyear;
      let mm = this.ismonth;
      let dd = this.isday;
      dateTime = [`${yy}-${mm < 10 ? "0" + mm : mm}-${dd}`];
      this_month = this.obtain_time_(yy, mm);
      Last_month = this.obtain_time_(
        mm != 1 ? yy : yy - 1,
        mm != 1 ? mm - 1 : 12
      );
      next_month = this.obtain_time_(
        mm != 12 ? yy : yy + 1,
        mm != 12 ? mm + 1 : 1
      );
      console.log(Last_month);
      this.this_month = this_month;
      // console.log(Last_month, this_month, next_month);
      console.log(this_month);
      console.log(this.dateTime.length);
    },
    // 获取 七天数据
    obtain_time_(yy, mm) {
      yy = Number(yy);
      mm = Number(mm);
      let item_list = [
        {
          time: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_children: [],
        },
      ]; // 返回数据
      if (this.Warehouse[`${yy}-${mm}`]) {
        item_list = this.Warehouse[`${yy}-${mm}`];
      } else {
        let dd = this.mGetDate_(yy, mm), // 获取当前月多少天
          last_days = this.days_fun(`${yy}-${mm}-1`), // 本月的第一天是周几
          next_days = this.days_fun(`${yy}-${mm}-${dd}`), // 本月的最后一天是周几
          last_list = [], // 返回数据
          next_list = []; // 返回数据
        item_list[0].item_children = this.item_list_fun(yy, mm, dd); // 获取当前所有时间
        if (last_days != 0) {
          // 第一天不是周日
          last_list = this.last_days_fun(
            mm != 1 ? yy : yy - 1,
            mm != 1 ? mm - 1 : 12,
            last_days
          );
        }
        if (next_days != 6) {
          // 最后一天不是周六
          next_list = this.next_days_fun(
            mm != 12 ? yy : yy + 1,
            mm != 12 ? mm + 1 : 1,
            next_days
          );
        }
        item_list[0].item_children = [
          ...last_list,
          ...item_list[0].item_children,
          ...next_list,
        ];

        this.Warehouse[`${yy}-${mm}`] = item_list;
      }
      return item_list;
    },
    // 当前日历本月数据
    item_list_fun(yy, mm, dd) {
      let item_children = [];
      for (let index = 1; index <= dd; index++) {
        item_children.push({
          item_title: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_text: index,
        });
      }
      return item_children;
    },
    // 当前日历展示第一行数据
    last_days_fun(yy, mm, days) {
      let item_children = [],
        Last_month = this.mGetDate_(yy, mm); // 上一月多少天
      for (let index = 0; index < days; index++) {
        item_children.unshift("");
      }
      // console.log(item_children);
      return item_children;
    },
    // 当前日历展示最后一行数据
    next_days_fun(yy, mm, days) {
      let item_children = [];
      for (let index = 1; index < 7 - days; index++) {
        item_children.push("");
      }
      return item_children;
    },
    // 计算当前日期
    Calculation_date_(yy, mm, dd, num) {
      let mGetDate = this.mGetDate_(yy, mm), // 当前月多少天
        Last_month = this.mGetDate_(
          mm != 1 ? yy : yy - 1,
          mm != 1 ? mm - 1 : 12
        ), // 上一月多少天
        new_mm = false,
        new_dd = false;
      if (num) {
        new_dd = dd + 7 > mGetDate ? true : false;
        dd = new_dd ? dd + 7 - mGetDate : dd + 7;
        if (new_dd) {
          new_mm = mm + 1 > 12 ? true : false;
          mm = new_mm ? 1 : mm + 1;
        }
        yy = new_mm ? yy + 1 : yy;
        // console.log(yy, mm, dd, num)
      } else {
        new_dd = dd - 7 < 1 ? true : false;
        dd = new_dd ? Last_month + (dd - 7) : dd - 7;
        if (new_dd) {
          new_mm = mm - 1 < 1 ? true : false;
          mm = new_mm ? 12 : mm - 1;
        }
        yy = new_mm ? yy - 1 : yy;
      }
      return `${yy}-${mm}-${dd}`;
    },
    // 本月多少天
    mGetDate_(year, month) {
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    // 周几
    days_fun(e) {
      return new Date(e).getDay();
    },
    // 点击日期
    new_catdate(e) {
      this.dateTime = e.item_title + "-" + e.item_text;
      console.log(this.dateTime);
      console.log(this.working_days != "");
      if (this.working_days != "") {
        this.jd_workday();
      }
      this.time_judge();
    },
    // 选择工作天数
    Workdays_fn() {
      if (this.dateTime != "") {
        this.jd_workday();
      }
      this.time_judge();
    },
    // 验证工作日期
    jd_workday() {
      var work_day = [];
      for (var i = 0; i < this.working_days; i++) {
        work_day.push(
          this.dateStr(
            new Date(
              Date.parse(this.dateTime + " " + this.startTime) + 86400000 * i
            )
          )
        );
      }
      jd_workday({ work_day: work_day.toString() }).then((res) => {
        if (!res.code) {
          return;
        } else {
          this.$message.error(
            // res.msg + ",节假日为:" + res.data.holidays_day.join("，")
            res.msg
          );
          this.working_days = "";
          this.dateTime = "";
        }
      });
    },
    // 上一个月
    preTime() {
      if (this.ismonth == 1) {
        this.isyear--;
        this.ismonth = 12;
      } else {
        this.ismonth--;
      }
      this.isday = 1;
      this.current_time_();
      if (this.SchedulingDrawer) {
        this.get_odd_all_wt();
      }
      // console.log(this.isyear, this.ismonth);
    },
    // 下一个月
    nextTime() {
      if (this.ismonth == 12) {
        this.isyear++;
        this.ismonth = 1;
      } else {
        this.ismonth++;
      }
      this.isday = 1;
      this.current_time_();
      if (this.SchedulingDrawer) {
        this.get_odd_all_wt();
      }
      // console.log(this.isyear, this.ismonth);
    },
    nodateTime() {
      this.dateTime = [];
      console.log(this.dateTime);
    },
    //时间转换
    dateStr(d, sign) {
      //如果没有传递符号，给一个默认的符号
      if (!sign) {
        sign = "-";
      }
      //获取d里面年月日时分秒
      let year = d.getFullYear(),
        month = d.getMonth() + 1,
        sun = d.getDate(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();

      // return (
      //   year +
      //   sign +
      //   this.mendZero(month) +
      //   sign +
      //   this.mendZero(sun) +
      //   " " +
      //   this.mendZero(hours) +
      //   ":" +
      //   this.mendZero(minutes) +
      //   ":" +
      //   this.mendZero(seconds)
      // );
      return year + sign + this.mendZero(month) + sign + this.mendZero(sun);
    },
    //时间转换补零
    mendZero(num) {
      return (num = num < 10 ? "0" + num : num);
    },

    // 学科遍历
    show_subject_json_data_fun(e) {
      let add = []
      e.forEach((item, index)=>{
        add[index] = this.course_fun(item)
      })
      return add
    },
    // 遍历学科 子级
    course_fun(item) {
      let Obj = {
        label: item.education,
        value: item.education,
        children: []
      }
      item.course.forEach((item_, index)=>{
        Obj.children[index] = {label: item_, value: item_}
      })
      console.log('Obj', Obj)
      return Obj
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    /*
    上架下架两块 
    hide 上架
    show  下架
    */
    //  this.loading = true;
    this.getJD();
    // 地区
    // showCity({ unlimited: 0 }).then((res) => {
    //   if (res.code === 0) {
    //     this.cn = res.data;
    //   }
    // });
    odd_jd_data().then((res) => {
      if (!res.code) {
        this.cn = res.data.place_list;
        console.log(res.data);
        // console.log(this.city_level,this.post_salary)
      }
    });
    // 是否为零工职位
    if (sessionStorage.getItem("odd_job") == 1) {
      this.isOddjob = true;
      this.company_create_time = sessionStorage.getItem("company_create_time");
      console.log(this.company_create_time);
    }
    this.odd_job_status = sessionStorage.getItem("odd_job_status")
    if(sessionStorage.getItem("odd_job_status") == 2){
      show_subject_json({})
      .then(res=>{
        this.show_subject_json_data = this.show_subject_json_data_fun(res.data)
      })
      .catch(err=>{  })
    }

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
/* //@import url(); 引入公共css类 */

.wrap {
  @include padding;
  min-width: 1100px;
  position: relative;
  padding-top: 54px;
  min-height: 75vh;
}

.fixed {
  position: fixed;
  top: 61px;
  width: 1100px;
  z-index: 500;
  padding: 30px 0 10px;
  margin: 0 auto;
  // display: flex;
  // justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
div /deep/ .el-button {
  width: 102px;
  height: 34px;
  padding: 0;
  float: left;
  text-align: center;
  vertical-align: middle;
  background-color: $main_color !important;
  color: #fff;
}
.search_wrap {
  float: right;
}

.search {
  position: relative;
  right: -5px;
  top: 0px;
  background-color: $main_color;
  width: 40px;
  height: 100%;
  color: #fff;
  line-height: 37px;
  border-radius: 0 40px 40px 0;
  font-size: 16px;
  vertical-align: middle;
  cursor: pointer;
}
div /deep/ .el-input {
  width: 200px;
}
div /deep/ .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.status {
  margin-left: 50px;
  cursor: pointer;
}
.action {
  color: $main_color;
}
/* .status:hover span {
  color: #00cab3;
} */

.public-style {
  margin-top: 20px;
}
//el tab
div /deep/ .el-tabs__active-bar {
  background-color: $main_color;
}
div /deep/ .el-tabs__item:hover {
  color: $main_color;
}
div /deep/ .el-tabs__item.is-active {
  color: $main_color;
}
.position {
  margin-top: 60px;
}

// 添加零工
.oddjob_wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 901;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.content_wrap {
  position: absolute;
  width: 970px;
  height: 80%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.oddjob_wrap .text_wrap {
  overflow-x: hidden;
  height: 100%;
  width: 970px;
  margin: auto;
  overflow-y: auto;
  background-color: #fff;
}
.oddjob_wrap .text_wrap_close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.text_submit {
  position: absolute;
  line-height: 40px;
  right: -40px;
  width: 100px;
  height: 40px;
  z-index: 300;
  background-color: #f90;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  top: -5px;
}
.text_exit {
  position: absolute;
  line-height: 40px;
  right: -40px;
  width: 100px;
  height: 40px;
  z-index: 300;
  background-color: #f90;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  top: 40px;
}

.form_wrap {
  height: calc(100% - 50px);
  padding-left: 30px;
  padding-top: 50px;
}
/* 修改input */
div /deep/ .el-form {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}
/* div /deep/ .el-form:after {
  display: block;
  content: '';
  width: 50%;
  height: 0px;
} */
div /deep/ .el-form-item {
  width: 50%;
}
div /deep/ .el-input {
  width: 300px;
}
.company_environment {
  width: 100%;
}
.company_environment /deep/ .el-form-item {
  width: 100%;
}

.area {
  width: 300px;
}

.area /deep/ .el-textarea__inner {
  min-height: 110px !important;
}
.shiduan {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.shiduan /deep/ .el-date-editor {
  width: 130px !important;
}
.shiduan /deep/ .el-input__inner {
  // display: flex;
  width: 130px !important;
  padding: 0 30px !important;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.Job-skills /deep/.el-button {
  float: none;
}
.Job-skills {
  width: 100% !important;
}
.right_div {
  width: 50%;
}
.right_div /deep/ .el-form-item {
  width: 100% !important;
}
.recruits /deep/ .el-input {
  width: 200px !important;
}

.calendar {
  margin: 20px auto;
  .timetop {
    display: flex;
    justify-content: center;
    .timetop_title {
      padding: 0 40px;
    }
  }
  .week {
    display: flex;
    flex-wrap: wrap;
    div {
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;
      width: 12%;
      margin: 1%;
    }
  }
  .time_list {
    display: flex;
    flex-wrap: wrap;
    .itemlist_div {
      text-align: center;
      margin: 1%;
      width: 12%;
      position: relative;
      .iconfont {
        position: absolute;
        top: 3px;
        right: 3px;
      }
      cursor: pointer;
      .old_day {
        color: #999;
      }
      .old_day:hover {
        cursor: not-allowed;
        border: 3px solid #fff;
      }
      .active_day {
        border: 3px solid #eee;
        background-color: #eee;
      }
      div {
        border: 3px solid #fff;
        line-height: 40px;
      }
      div:hover {
        border: 3px solid #efefef;
      }
      .no_class {
        cursor: default;
      }
      .no_class:hover {
        border: 3px solid #fff;
      }
    }
  }
  .TimeSelect {
    border: 3px solid #ff8201 !important;
    position: relative;
    .iconfont_wrap {
      display: block !important;
      position: absolute;
      top: 2px;
      right: 2px;
      color: #000;
    }
  }
  .iconfont_wrap {
    display: none;
  }
  .oldSelect {
    border: 3px solid #ff8201 !important;
    background-color: #ff8201;
    color: #fff;
  }
}
.form_age /deep/ .el-form-item__content {
  display: flex;
  width: 300px;
  justify-content: space-between;
}
.form_age /deep/ .el-input {
  width: 130px;
}

.agreement_wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 901;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.agreement {
  position: absolute;
  height: 80%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 970px;
  margin: auto;
}
.agreement .text {
  width: 970px;
  padding: 0 30px;
  background: #fff;
  height: 90%;
  width: 100%;
  overflow: auto;
}

.agreement .close {
  position: absolute;
  right: -25px;
  top: 20px;
  cursor: pointer;
}

.agreement h4 {
  text-align: center;
  font-size: 24px;
}
.agreement h3 {
  text-align: center;
  font-size: 18px;
}

.agreement .tips {
  font-size: 14px;
  line-height: 22px;
}
.agreement .isok {
  height: 10%;
  width: 100%;
  background-color: #fff;
  padding: 0 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement .ok_button {
  background-color: #07c160;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
}
</style>